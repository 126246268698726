(function() {

  $('.js-fb-toggle').on('click', function(e) {
    e.preventDefault();
    $(this).toggleClass('clicked');
    $(this).find('.js-fastbooker-text').toggleClass('clicked');
    $(this).siblings('.js-fb-box').slideToggle();
  });

  var d = new Date(new Date().getTime());
  var t = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  var today = d.getDate() + "/" + ('0' + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear();
  var tomorrow = t.getDate() + "/" + ('0' + (t.getMonth() + 1)).slice(-2) + "/" + t.getFullYear();

  //FASTBOOKER
  var arrival = $('#startdate');
  arrival.val(today);
  var departure = $('#enddate');
  departure.val(tomorrow);
  arrival.datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      startDate: 'd',
      weekStart: 1,
      orientation: 'bottom'
  }).on('changeDate', function (e) {
      departure.focus();
      var selectedDate = arrival.datepicker('getDate');
      var nextDay = new Date(selectedDate);
      nextDay.setDate(nextDay.getDate() + 1);
      departure.datepicker('setDate', nextDay);
      departure.datepicker('setStartDate', nextDay);
  });
  departure.datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      startDate: tomorrow,
      weekStart: 1,
      orientation: 'bottom'
  });
  var btn = $('.fastbooker__btn');
  btn.click(function (e) {
      e.preventDefault();
      var arrival = $('#startdate').val();
      arrival = arrival.split('/');
      arrival = arrival[2] + '-' + arrival[1] + '-' + arrival[0];
      var departure = $('#enddate').val();
      departure = departure.split('/');
      departure = departure[2] + '-' + departure[1] + '-' + departure[0];
      var discount = $('#coupon').val();
      var href = "https://reservations.cubilis.eu/next-door-bed-and-breakfast-brugge/Rooms/Select?Language=" + getCubilisLang();
      if ((arrival !== 'undefined-undefined-') && (departure !== 'undefined-undefined-')) {
          href = "https://reservations.cubilis.eu/next-door-bed-and-breakfast-brugge/Rooms/Select?Language=" + getCubilisLang() + "&Arrival=" + arrival + "&Departure=" + departure + "&DiscountCode=" + discount;
      }
      window.open(href, '_blank');
  });


  function getCubilisLang()
  {
    var lang = $('html').attr('lang');
      var cubilisLang = 'en-GB';
      if (lang === 'nl') {
          cubilisLang = 'nl-NL';
      } else if (lang === 'fr') {
          cubilisLang = 'fr-FR';
      } else if (lang === 'de') {
          cubilisLang = 'de-DE';
      } else if (lang === 'ru') {
          cubilisLang = 'ru-RU';
      }
      return cubilisLang;
  }

})();
