require('./polyfills/classlist.js');
require('./polyfills/dataset.js');

require('./plugins/datepicker.js');

require('./modules/sliders.js');
require('./modules/nav.js');
require('./modules/mobile-nav.js');
require('./modules/reviews.js');
require('./modules/gallery.js');
require('./modules/location.js');
require('./modules/forms.js');
require('./modules/fastbooker.js');
