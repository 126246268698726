(function(){

  var subItems = document.querySelectorAll('.menu_level_1 .js-highlight');
  var subItemsArr = Array.prototype.slice.call(subItems);
  subItemsArr.forEach(function(item){
    item.classList.remove('js-highlight');
  });

  var header = document.querySelector('.js-header');
  var items = header.querySelectorAll('.js-highlight');

  var activeItem = header.querySelector('.active');
    if (activeItem == null) {
      activeItem = items[0];
    }
    if (activeItem.parentNode.classList.contains('menu_level_1')) {
      activeItem = header.querySelector('.nav .current_ancestor');
    };

  var highlight = document.createElement('span');

  highlight.classList.add('highlight');
  header.appendChild(highlight);

  function highlightInit() {
    var activeCoords = activeItem.getBoundingClientRect();
    var initCoords = {
      width: activeCoords.width,
      left: activeCoords.left
    }
    highlight.classList.add('isVisible');
    highlight.style.width = initCoords.width + 'px';
    highlight.style.transform = 'translateX(' + initCoords.left + 'px)';
  }

  function triggerHighlight() {
    var linkCoords = this.getBoundingClientRect();

    var coords = {
      width: linkCoords.width,
      left: linkCoords.left
    }

    highlight.style.width = coords.width + 'px';
    highlight.style.transform = 'translateX(' + coords.left + 'px)';

  };


  var itemsArr = Array.prototype.slice.call(items);
  itemsArr.forEach(function(item){
    item.addEventListener('mouseenter', triggerHighlight);
  });


  if (typeof(activeItem) != 'undefined' && activeItem != null) {
    header.addEventListener('mouseleave', function(){
      highlightInit();
    });

    highlightInit();
  }


})();
