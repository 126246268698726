var gmaps = require('google-maps');
gmaps.KEY = 'AIzaSyAHU-9pXFbauug9IiPFYFGnreCED59OVbw';

(function() {
    if ($('.js-maps').length) {
        gmaps.load(function(google) {
            var el = document.querySelector('.js-maps');
            var lat = 51.204407;
            var long =  3.231258;
            var center = new google.maps.LatLng(lat, long);

            var icon = {
              url: '/bundles/sitefrontend/img/marker.svg',
              scaledSize: new google.maps.Size(65, 65)
            };

            var options = {
                zoom: 15,
                center: center,
								scrollwheel: false
								};

            var map = new google.maps.Map(el, options);

            google.maps.event.addDomListener(window, "resize", function() {
                var center = map.getCenter();
                google.maps.event.trigger(map, "resize");
                map.setCenter(center);
            });

            var marker = new google.maps.Marker({
                position: center,
                map: map
            });
        });
    }
})();
