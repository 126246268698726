var slick = require('slick-carousel');

(function(){

$('.hero__slider').slick({
  fade: true,
  autoplay: true
});

$('.js-gallery').slick({
  fade: true,
  autoplay: true,
  nextArrow: '<div class="slick__arrow slick__arrow--next"></div>',
  prevArrow: '<div class="slick__arrow slick__arrow--prev"></div>'
});

})();
